// src/components/CustomSlider/CustomSlider.js
import React, { useState, useCallback, useEffect } from "react";
import { Slider } from "rsuite";

const CustomSlider = ({ siteName, onPeriodChange }) => {
    const period = localStorage.getItem("period") === null ||
        localStorage.getItem("period") === undefined ||
        !(localStorage.getItem("period") >= 3) ? 24 : localStorage.getItem("period");
        
    const [value, setValue] = useState(period);

    const handleSliderChange = (value) => {
        setValue(value);
        localStorage.setItem("period", value);
        onPeriodChange(value); // Notifica o componente pai sobre a mudança no período
    };

    const handleSliderChangeCommitted = useCallback(() => {
        const formData = new FormData();
        formData.append("action", "get");
        formData.append("name", siteName);
        formData.append("period", localStorage.getItem("period"));
        onPeriodChange(value);
    }, [siteName, value, onPeriodChange]);

    useEffect(() => {
        handleSliderChangeCommitted();
    }, []);

    return (
        <div>
            <div>Dados das últimas {value}h</div>
            <Slider
                defaultValue={24}
                min={3}
                step={3}
                max={72}
                value={value}
                onChange={handleSliderChange}
                onChangeCommitted={() => handleSliderChangeCommitted(value)}
                graduated
                progress
                renderMark={mark => `${mark}h`}
            />
        </div>
    );
};

export default CustomSlider;
