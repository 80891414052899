import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AutomationChart = ({ data, medicao }) => {
  const [datasets, setDatasets] = useState([]);

  // Função para calcular a média de um array
  const calculateAverage = (array) => {
    const sum = array.reduce((acc, value) => acc + value, 0);
    return (sum / array.length) || 0;
  };

  // Formatando as datas para ficarem mais legíveis
  const formatDate = (dateString) => {
    const options = { day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  useEffect(() => {
    switch (medicao) {
      case 'loading':
        const loadingTimes = data.map(entry => entry.loading_time);
        const averageLoadingTime = calculateAverage(loadingTimes);
        setDatasets(
          [
            {
              label: 'Tempo de Carregamento',
              data: loadingTimes,
              borderColor: 'rgba(153, 102, 255, 1)',
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
            },
            {
              label: 'Média Tempo de Carregamento',
              data: new Array(data.length).fill(averageLoadingTime),
              borderColor: 'rgba(54, 162, 235, 1)',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderDash: [10, 5],
            },
          ]
        );
        break;

      case 'login':
        const loginTimes = data.map(entry => entry.login_time);
        const averageLoginTime = calculateAverage(loginTimes);
        setDatasets(
          [
            {
              label: 'Tempo de Login',
              data: loginTimes,
              borderColor: 'rgba(255, 159, 64, 1)',
              backgroundColor: 'rgba(255, 159, 64, 0.2)',
            },
            {
              label: 'Média Tempo de Login',
              data: new Array(data.length).fill(averageLoginTime),
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderDash: [10, 5],
            },
          ]
        );
        break;

      default:
        const transferSize = data.map(entry => entry.total_transfer_size);
        const averageTransferSize = calculateAverage(transferSize);
        setDatasets(
          [
            {
              label: 'Tamanho Total de Transferência',
              data: transferSize,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
            },
            {
              label: 'Média de Transferência',
              data: new Array(data.length).fill(averageTransferSize),
              borderColor: 'rgba(75, 192, 50, 1)',
              backgroundColor: 'rgba(75, 192, 50, 0.2)',
              borderDash: [10, 5],
            },
          ]
        );
        break;
    }
  }, [data, medicao]);

  const chartData = {
    labels: data.map(entry => "Dia: " + formatDate(entry.start_time) + " -"),
    datasets: datasets
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        }
      },
      title: {
        display: true,
        text: 'Dados de Automação das Últimas 24 Horas',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2);
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        reverse: true,
        title: {
          display: true,
          text: 'Hora de Início'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Valor'
        }
      }
    }
  };

  return <Line data={chartData} options={options} />;
};

export default AutomationChart;
