import TableComponent from "../TableComponent/TableComponent";

function TableCremescGED() {
    return (
        <>
            <TableComponent siteName={"CremescGED"} />
        </>
    );
}

export default TableCremescGED;