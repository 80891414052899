import TableComponent from "../TableComponent/TableComponent";

function TableCremersEDT() {
    return (
        <>
            <TableComponent siteName={"CremersEDT"} />
        </>
    );
}

export default TableCremersEDT;