import { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext/AuthContext';
import useSendData from '../../Hooks/useSendData';
import { Button, Form, InputGroup, Modal } from 'rsuite';
import EmailFillIcon from '@rsuite/icons/EmailFill';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

const AuthCheck = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { sendData, loading, error, data } = useSendData();
    const { isAuthenticated, setIsAuthenticated } = useAuth();

    const handleChangeVisibility = () => {
        setVisible(!visible);
    };

    const handleLogin = () => {
        const formData = new FormData();
        formData.append('action', 'login');
        formData.append('email', email);
        formData.append('password', password);
        console.log(formData);
        sendData('user', formData, 'POST', true);

        if (data && data.authenticated) {
            localStorage.setItem('token', data.token);
            setIsAuthenticated(true);
        }
    };

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            const formData = new FormData();
            formData.append('token', token);
            sendData('check_user', formData, 'POST', true);
        } else {
            setIsAuthenticated(false);
        }
    }, [sendData, setIsAuthenticated]);

    useEffect(() => {
        if (error || (data && (data.token === null || data.token === undefined))) {
            setIsAuthenticated(false);
        } else if (data && data.token !== undefined) {
            sessionStorage.setItem('token', data.token);
            setIsAuthenticated(true);
        }
    }, [error, data, setIsAuthenticated]);

    if (!isAuthenticated) {
        return (
            <Modal backdrop={'static'} size={'40rem'} open={!isAuthenticated}>
                <Modal.Header>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form layout="horizontal" onSubmit={handleLogin}>
                        <Form.Group controlId="email-6">
                            <InputGroup inside>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder='Seu e-mail'
                                    value={email}
                                    onChange={value => setEmail(value)}
                                />
                                <InputGroup.Addon>
                                    <EmailFillIcon />
                                </InputGroup.Addon>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="password-6">
                            <InputGroup inside>
                                <Form.Control
                                    name="password"
                                    type={visible ? 'text' : 'password'}
                                    autoComplete="off"
                                    placeholder='Sua senha'
                                    value={password}
                                    onChange={value => setPassword(value)}
                                />
                                <InputGroup.Button onClick={handleChangeVisibility}>
                                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                                </InputGroup.Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                                <Button appearance="primary" type="submit">
                                    Submit
                                </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    return children;
};

export default AuthCheck;
