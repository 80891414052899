import TableComponent from "../TableComponent/TableComponent";

function TableCremersTI() {
    return (
        <>
            <TableComponent siteName={"CremersTI"} />
        </>
    );
}

export default TableCremersTI;