import TableComponent from "../TableComponent/TableComponent";

function TableCremersGED() {
    return (
        <>
            <TableComponent siteName={"CremersGED"} />
        </>
    );
}

export default TableCremersGED;