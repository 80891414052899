import { useEffect, useState } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { Tooltip, Whisper, ButtonGroup, Button, FlexboxGrid, Divider } from 'rsuite';
import AutomationChart from '../AutomationChart/AutomationChart';
import BlockIcon from '@rsuite/icons/Block';

function TableStandard({ name, data }) {
  const [processedData, setProcessedData] = useState([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [medicao, setMedicao] = useState(localStorage.getItem('medicao') ?? 'loading');

  // Função para converter bytes em uma unidade legível
  const convertBytes = (sizeInBytes) => {
    if (sizeInBytes < 1024) return `${sizeInBytes} bytes`;
    if (sizeInBytes < 1024 * 1024) return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    return `${(sizeInBytes / (1024 ** 2)).toFixed(2)} MB`;
  };

  // Função para processar e formatar os dados
  const noData = <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Nulo ou não testado</Tooltip>}><BlockIcon /></Whisper>;
  const processData = (data) => {
    return data.map(item => ({
      ...item,
      start_time: new Date(item.start_time).toLocaleString(),
      end_time: new Date(item.end_time).toLocaleString(),
      total_transfer_size: convertBytes(item.total_transfer_size),
      loading_time: item.loading_time ? item.loading_time.toFixed(2) + " s" : noData,
      login_time: item.login_time ? item.login_time.toFixed(2) + " s" : noData
    }));
  };

  // Função para ordenar os dados
  const getSortedData = () => {
    if (sortColumn && sortType) {
      const sorted = [...data].sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') x = x.charCodeAt();
        if (typeof y === 'string') y = y.charCodeAt();
        return sortType === 'asc' ? x - y : y - x;
      });
      return processData(sorted);
    }
    return processData(data);
  };

  useEffect(() => {
    setProcessedData(getSortedData());
  }, [data, sortColumn, sortType]);

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleSetMedicao = (value) => {
    setMedicao(value);
    localStorage.setItem('medicao', value);
  };

  return (
    <>
      <Divider><h4>Tabela {name}</h4></Divider>
      <Table
        height={420}
        data={processedData}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        onRowClick={rowData => {
          console.log(rowData.automation_id);
        }}
      >
        <Column width={50} align="center" fixed sortable>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="automation_id" />
        </Column>
        <Column width={100}>
          <HeaderCell>Navegador</HeaderCell>
          <Cell dataKey="browser" />
        </Column>
        <Column width={180}>
          <HeaderCell>Horário de Início</HeaderCell>
          <Cell dataKey="start_time" />
        </Column>
        <Column width={180}>
          <HeaderCell>Horário Final</HeaderCell>
          <Cell dataKey="end_time" />
        </Column>
        <Column width={150}>
          <HeaderCell>Transferência</HeaderCell>
          <Cell dataKey="total_transfer_size" />
        </Column>
        <Column width={200} sortable>
          <HeaderCell>Tempo de Carregamento</HeaderCell>
          <Cell dataKey="loading_time" />
        </Column>
        <Column flexGrow={2} sortable>
          <HeaderCell>Tempo de Login</HeaderCell>
          <Cell dataKey="login_time" />
        </Column>
      </Table>
      <Divider>Gráfico {name}</Divider>
      <FlexboxGrid justify='center'>
        <ButtonGroup>
          <Button onClick={() => { handleSetMedicao("loading") }}>Gráfico do Carregamento</Button>
          <Button onClick={() => { handleSetMedicao("login") }}>Gráfico do Login</Button>
          <Button onClick={() => { handleSetMedicao("transfersize") }}>Gráfico da Transferência</Button>
        </ButtonGroup>
      </FlexboxGrid>
      <AutomationChart data={data} medicao={medicao} />
    </>
  );
}

export default TableStandard;
