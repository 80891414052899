import React, { useCallback } from "react";
import useSendData from "../../Hooks/useSendData";
import TableStandard from "../TableStandard/TableStandard";
import { Loader } from "rsuite";
import CustomSlider from "../CustomSlider/CustomSlider";

function TableComponent({ siteName }) {
    const { sendData, loading, error, data } = useSendData();

    const handlePeriodChange = useCallback((newPeriod) => {
        const formData = new FormData();
        formData.append("action", "get");
        formData.append("name", siteName);
        formData.append("period", newPeriod);

        sendData('automation', formData, 'POST', true);
    }, [sendData, siteName]);

    return (
        <>
            <CustomSlider siteName={siteName} onPeriodChange={handlePeriodChange} />
            <div className="cd-margin-botton"></div>
            {loading && <div style={{ textAlign: "center" }}><Loader size="lg" content="Carregando..." vertical /></div>}
            {error && <p>Error: {error.message}</p>}
            {!loading && !error && (
                <>
                    <TableStandard name={data['name']} data={data['info'] || []} />
                </>
            )}
        </>
    );
}

export default TableComponent;
