import TableComponent from "../TableComponent/TableComponent";

function TableCremersCD() {
    return (
        <>
            <TableComponent siteName={"CremersCD"} />
        </>
    );
}

export default TableCremersCD;