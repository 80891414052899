import './App.css';
import 'rsuite/dist/rsuite.min.css';
import { AuthProvider } from './components/AuthContext/AuthContext';
import { Col, CustomProvider, FlexboxGrid } from 'rsuite';
import { useState } from 'react';
import HeaderFragment from './fragments/HeaderFragment/HeaderFragment';
import TableCremersGED from './components/TableCremersGED/TableCremersGED';
import TableCremersTI from './components/TableCremersTI/TableCremersTI';
import TableCremescGED from './components/TableCremescGED/TableCremescGED';
import TableCremersEDT from './components/TableCremersEDT/TableCremersEDT';
import TableCremersCD from './components/TableCremersCD/TableCremersCD';
import TableAverage from './components/TableAverage/TableAverage';
import AuthCheck from './components/AuthCheck/AuthCheck';

function App() {
  const [currentComponent, setCurrentComponent] = useState("TableAverage");

  const renderComponent = () => {
    switch (currentComponent) {
      case "TableAverage":
        return <TableAverage />;
      case "TableCremersGED":
        return <TableCremersGED />;
      case "TableCremersTI":
        return <TableCremersTI />;
      case "TableCremersEDT":
        return <TableCremersEDT />;
        case "TableCremersCD":
        return <TableCremersCD />;
      case "TableCremescGED":
        return <TableCremescGED />;
      default:
        return <TableAverage />;
    }
  };

  return (
    <CustomProvider theme="dark">
      <AuthProvider>
        <HeaderFragment setCurrentComponent={setCurrentComponent} />
        <AuthCheck>
          <FlexboxGrid justify='center'>
            <FlexboxGrid.Item as={Col} colspan={24} md={23}>
              {renderComponent()}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </AuthCheck>
      </AuthProvider>
    </CustomProvider>
  );
}

export default App;
